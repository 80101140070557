// this is currently a helper function used to pass a
// normalized content asset objects to the ahoy tracking
// library.  right now there are many different formats
// of it lying around - `content_asset`, `contentAsset`, etc
//
// it might be better suited to a 'content_asset' model or store
// if something like that ever materializes.
export function assetEventData(contentAsset) {
  return {
    asset_type: contentAsset.type,
    asset_id: contentAsset.id,
    account_id: contentAsset.account_id || contentAsset.account.id,
    asset_identifier: contentAsset.identifier,
    source: contentAsset.source,
    url: window.location.href,
  }
}
