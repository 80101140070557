<template lang="html">
  <div class="branded-header">
    <div v-if="logoSizeOption == 'small'">
      <span v-html="logoSvg" class="logo" aria-hidden></span>
    </div>

    <div v-if="logoSizeOption == 'large'">
      <div class="d-flex justify-content-center pt-2">
        <span v-html="logoSvg" class="logo" aria-hidden></span>
      </div>
    </div>

    <div v-if="question" class="question font-weight-bold mt-1">{{ question }}</div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    logoSvg: { type: String, required: true }, // all assets show a logo
    surveySummary: { type: String, required: false }, // testimonials do not have a survey summary
    question: { type: String, required: false }, // stats don't show the question
    logoSizeOption: { type: String, default: 'small' },
  },
  computed: {
    showLogo() {
      return this.logoSizeOption !== 'none'
    },
  },
}
</script>
<style lang="sass" scoped>
.logo
  height: 24px
.question
  font-size: 1.25rem
  line-height: 1.25
</style>
