// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/home/ue-email-signup-background-shape.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./graphics/radio_button.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "header[data-v-28d10122]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),var(--header-gradient)}.testimonial[data-v-28d10122]{margin-bottom:96px;overflow:visible !important}.bar_guts[data-v-28d10122] .bar_group .channel{border-radius:0 8px 8px 0;background:#f2f6f7 !important}.bar_guts[data-v-28d10122].legend{display:none !important}.chart_preview .vertical[data-v-28d10122]{height:387px}.chart_preview h4[data-v-28d10122]{margin-bottom:20px}.chart_preview .citation[data-v-28d10122]{margin-top:20px;font-size:12px}ul li[data-v-28d10122]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-position:left center;padding-left:20px;font-weight:700;list-style:none;line-height:1.5}", ""]);
// Exports
module.exports = exports;
