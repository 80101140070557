import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    content_asset: null,
  },
  mutations: {
    CLEAR_CONTENT_ASSET(state) {
      state.content_asset = null
    },
    SET_CONTENT_ASSET(state, content_asset) {
      state.content_asset = content_asset
    },
  },
})
