<template>
  <div v-if="contentAssets.length > 0" class="assets">
    <div v-for="asset in contentAssets" :key="asset.id" class="asset">
      <TestimonialBasic
        v-if="asset.type == 'RenderableTestimonial'"
        :renderable-testimonial="asset"
        :account="asset.account"
      />
      <BarChartBasic v-else-if="asset.type == 'ChartAsset'" :content-asset="asset" />
      <StatBasic v-else-if="asset.type == 'StatAsset'" :content-asset="asset" />
      <Microsite v-else-if="asset.type == 'AssetList'" :asset_list="asset" />
      <MatrixxChartBasic
        v-else-if="asset.type == 'MatrixxChart'"
        :account="asset.account"
        :matrixxChart="asset"
      />
      <SpotlightPreview
        v-else-if="['CustomerSpotlight', 'SurveySpotlightAsset'].includes(asset.type)"
        :content_asset="asset"
      />
    </div>
  </div>
  <div v-else class="assets none">
    <h3>No assets match those filters.</h3>
  </div>
</template>

<script>
import TestimonialBasic from '@components/TestimonialBasic'
import BarChartBasic from '@components/BarChartBasic'
import StatBasic from '@components/StatBasic'
import Microsite from '@app/components/Microsite'
import SpotlightPreview from '@components/SpotlightPreview'
import MatrixxChartBasic from '@components/MatrixxChartBasic'

export default {
  components: {
    TestimonialBasic,
    BarChartBasic,
    StatBasic,
    Microsite,
    SpotlightPreview,
    MatrixxChartBasic,
  },
  props: {
    contentAssets: {
      type: Array,
      default: () => [],
    },
    assetList: {
      type: Object,
    },
  },
  methods: {
    assetUrl(asset) {
      return `/assets/${asset.identifier}`
    },
    setCookie() {
      this.$cookies.set('site-back-url', this.$router.history.current.fullPath)
      this.$cookies.set('site-back-text', `Microsite: ${this.asset_list.title}`)
    },
  },
}
</script>
<style lang="sass" scoped>
.assets
  columns: 2
.assets.none
  min-height: 35vh
.asset
  display: inline-block
  margin-bottom: 24px
  width: 100% !important
</style>
