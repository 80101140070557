import { render, staticRenderFns } from "./CopyShareLink.vue?vue&type=template&id=635d3aaa&lang=pug"
import script from "./CopyShareLink.vue?vue&type=script&lang=ts"
export * from "./CopyShareLink.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports