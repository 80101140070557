import { Model } from '@vuex-orm/core'
import Survey from './Survey'
import Response from './Response'
import Cohort from './Cohort'
import ContentAsset from './ContentAsset'

export default class Recipient extends Model {
  static entity = 'recipients'

  static fields() {
    return {
      id: this.number(null),
      state: this.string(''),
      token: this.string(''),
      slide: this.number(null),
      email: this.string(''),
      first_name: this.attr(null),
      last_name: this.attr(null),
      company_name: this.attr(null),
      best_company_name: this.attr(null),
      size_group: this.attr(null),
      domain_id: this.number(null),
      title: this.attr(null),
      named: this.boolean(false),
      nps_score: this.number(null),
      survey_id: this.number(null),
      survey: this.belongsTo(Survey, 'survey_id'),
      cohort_id: this.number(null),
      cohort: this.belongsTo(Cohort, 'campaign_id'),
      industry_id: this.number(null),
      recipient_role_id: this.number(null),
      seniority: this.attr(null),
      responses: this.hasMany(Response, 'recipient_id'),
      has_company_info: this.boolean(false),
      has_personal_info: this.boolean(false),
      last_response_at: this.attr(null),
      content_assets: this.morphMany(ContentAsset, 'assetable_id', 'assetable_type'),
      // content assets are has many through responses
      // but the customer spot light asset is just a polymorphic
      // belongs to.  rails makes a distinction, so we have to send it
      // in via this different key, but vuex orm doesnt, so they
      // all just show up in the content assets key, this is only here
      // to facilitate the shape that has to come from rails.... :(
      customer_spotlight_asset: this.morphOne(ContentAsset, 'assetable_id', 'assetable_type'),
      completed_at: this.attr(null),
      responded: this.boolean(false),
    }
  }
}
