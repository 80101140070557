import { render, staticRenderFns } from "./UserIcon.vue?vue&type=template&id=19a88380&scoped=true"
import script from "./UserIcon.vue?vue&type=script&lang=js"
export * from "./UserIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a88380",
  null
  
)

export default component.exports