import { Model } from '@vuex-orm/core'
import AssetList from './AssetList'
import ContentAsset from './ContentAsset'

export default class AssetListItem extends Model {
  static entity = 'asset_list_items'

  static fields() {
    return {
      id: this.number(null),
      position: this.number(null),
      asset_listable_id: this.attr(null),
      asset_listable_type: this.attr(null),
      asset_list_id: this.number(null),
      asset_list: this.belongsTo(AssetList, 'asset_list_id'),
      content_asset: this.attr(null),
    }
  }
}
