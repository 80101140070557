import { Model } from '@vuex-orm/core'
import StatTemplate from './StatTemplate'
import Recipient from './Recipient'
import Question from './Question'
import Response from './Response'

export default class ContentAsset extends Model {
  static entity = 'content_assets'

  static fields() {
    return {
      id: this.number(null),
      state: this.string(''),
      type: this.string(''),
      title: this.attr(null),
      text: this.attr(),
      testimonial_text: this.string(''),
      stat_template_id: this.number(null),
      headline: this.attr(null),
      sentence: this.attr(null),
      rendered_headline: this.attr(null),
      rendered_sentence: this.attr(null),
      show_question: this.attr(null),
      question_text: this.attr(null),
      avatar_url: this.attr(null),
      author_name: this.attr(null),
      author_title: this.attr(null),
      author_company: this.attr(null),
      assetable_id: this.attr(null),
      assetable_type: this.attr(null),
      stat_type: this.string(''),
      survey_id: this.attr(null),
      nps_score: this.attr(null),
      show_nps: this.attr(null),
      answer_stats: this.attr(null),
      question: this.attr(null),
      stats: this.attr(null),
      effective_response_count: this.attr(null),
      response_count: this.attr(null),
      recipient_count: this.attr(null),
      stat_template: this.belongsTo(StatTemplate, 'stat_template_id'),
      // recipient: this.belongsTo(Recipient, 'recipient_id'),
      renderable: this.morphTo('renderable_id', 'renderable_type'),
      assetable: this.morphTo('assetable_id', 'assetable_type'),
      // asset_listable: this.morphTo('asset_listable_id', 'asset_listable_type'),
      questions: this.hasMany(Question, 'content_asset_id'),
      responses: this.hasMany(Response, 'content_asset_id'),
      account: this.attr(null),
    }
  }
}
