import { Model } from '@vuex-orm/core'
import AssetListItem from './AssetListItem'

export default class AssetList extends Model {
  static entity = 'asset_lists'

  static fields() {
    return {
      id: this.number(null),
      slug: this.string(''),
      token: this.string(''),
      title: this.string(''),
      asset_list_items: this.hasMany(AssetListItem, 'asset_list_id'),
      public: this.attr(null),
      include_filters: this.attr(null),
      include_search: this.attr(null),
      include_tags: this.attr(null),
    }
  }
}
