import { Model } from '@vuex-orm/core'
import ContentAsset from './ContentAsset'

export default class StatTemplate extends Model {
  static entity = 'stat_templates'

  static fields() {
    return {
      id: this.number(null),
      stat_type: this.string(''),
      headline: this.string(''),
      sentence: this.string(''),
      templateable_id: this.attr(null),
      templateable_type: this.string(''),
      sample_asset: this.attr(null),
      published_asset_count: this.attr(null),
      content_assets: this.hasMany(ContentAsset, 'stat_template_id')
    }
  }
}
