import { render, staticRenderFns } from "./AvatarIcon.vue?vue&type=template&id=650a540e&scoped=true&lang=html"
import script from "./AvatarIcon.vue?vue&type=script&lang=ts"
export * from "./AvatarIcon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "650a540e",
  null
  
)

export default component.exports