//
// This Mixin unifies logic shared by all Branded Assets
//
import {
  getBasicColor,
  getBrandedBackgroundColor,
  getBrandedFooterColor,
  UE_BLACK,
  useDarkTextForColor,
} from '@lib/colors'

export default {
  // Every branded asset will have at least these three options
  props: {
    // This is the color that will be used to fill the background
    // type below should be `backgroundColorOptions`, but for some reason the export is not working
    backgroundColorOption: { type: String, default: 'primary' },

    // the size of the logo that will be displayed
    // type below should be `logoSizeOptions`, but for some reason the export is not working
    logoSizeOption: { type: String, default: 'none' },
  },
  computed: {
    statColor() {
      return this.getBasicColor(this.account, this.statColorOption)
    },
    // This is currently not an option for the user
    footerColor() {
      if (this.backgroundColorOption === 'dark') {
        return 'white'
      }

      const color = this.account.secondary_color || this.account.primary_color
      return useDarkTextForColor(color) ? UE_BLACK : 'white'
    },
    chartColor() {
      return this.getBasicColor(this.account, this.chartColorOption)
    },
    bothBackgroundCssVars() {
      const background = this.account.secondary_color
        ? `linear-gradient(170deg, ${this.account.primary_color}, ${this.account.primary_color} 50%, ${this.account.secondary_color} 40%, ${this.account.secondary_color})`
        : this.account.primary_color

      if (this.backgroundColorOption === 'both') {
        return {
          '--brand-color-1': this.account.primary_color,
          '--brand-color-2': this.account.secondary_color || this.account.primary_color,
          '--background': background,
        }
      } else {
        return {
          '--background': this.backgroundColor(),
        }
      }
    },
  },
  methods: {
    backgroundColor() {
      return this.getBrandedBackgroundColor(this.account, this.backgroundColorOption)
    },
    getBasicColor,
    getBrandedBackgroundColor,
    getBrandedFooterColor,
  },
}
