import { Model } from '@vuex-orm/core'
import Survey from './Survey'

export default class SendRequest extends Model {
  static entity = 'send_requests'

  static fields() {
    return {
      id: this.number(null),
      position: this.number(null),
      state: this.attr(null),
      subject: this.attr(null),
      sent_at: this.attr(null),
      queued_at: this.attr(null),
      requested_at: this.attr(null),
      scheduled_at: this.attr(null),
      sent_to_count: this.number(null),
      unrequested_recipient_count: this.number(null),
      requested_recipient_count: this.number(null),
      survey_id: this.number(null),
    }
  }
}
