import { Model } from '@vuex-orm/core'
import Survey from './Survey'
import Recipient from './Recipient'

export default class Cohort extends Model {
  static entity = 'cohorts'

  static fields() {
    return {
      id: this.number(null),
      type: this.string(''),
      state: this.string(''),
      name: this.string(''),
      token: this.string(''),
      logo_url: this.string(''),
      survey_id: this.number(null),
      survey: this.belongsTo(Survey, 'survey_id'),
      subject: this.attr(null),
      sender_first_name: this.attr(null),
      sender_last_name: this.attr(null),
      sender_from_email_address: this.attr(null),
      sender_phone_number: this.attr(null),
      sender_title: this.attr(null),
      scheduled_at: this.attr(null),
      recipient_count: this.number(null),
      unscheduled_recipient_count: this.number(null),
      recipients: this.hasMany(Recipient, 'campaign_id')
    }
  }
}
