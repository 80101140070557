import { Model } from '@vuex-orm/core'
import Question from './Question'
import Response from './Response'

export default class Answer extends Model {
  static entity = 'answers'

  static fields() {
    return {
      id: this.number(null),
      position: this.number(null),
      the_answer: this.attr(null),
      possible_values: this.string(''),
      question_id: this.number(null),
      freeform: this.boolean(false),
      question: this.belongsTo(Question, 'question_id'),
      responses: this.hasMany(Response, 'answer_id')
    }
  }
}