import { Model } from '@vuex-orm/core'
import Answer from './Answer'
import Recipient from './Recipient'
import Question from './Question'
import ContentAsset from './ContentAsset'

export default class Response extends Model {
  static entity = 'responses'

  static fields() {
    return {
      id: this.number(null),
      answer_id: this.number(null),
      value_answer: this.number(null),
      text_answer: this.attr(null),
      answer: this.belongsTo(Answer, 'answer_id'),
      recipient_id: this.number(null),
      recipient: this.belongsTo(Recipient, 'recipient_id'),
      question_id: this.number(null),
      question: this.belongsTo(Question, 'question_id'),
      content_asset_id: this.number(null),
      content_assets: this.morphMany(ContentAsset, 'assetable_id', 'assetable_type'),
      sentiment: this.attr(null),
    }
  }
}
