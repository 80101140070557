import Vue from 'vue'
import VueRouter from 'vue-router'
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'
import VueCalendly from 'vue-calendly'
import AccountIndex from '../src/site/components/accounts/Index'
import AccountShow from '../src/site/components/accounts/Show'
import AssetShow from '../src/site/components/assets/ShowWrapper'
import AssetListShow from '../src/site/components/asset_lists/Show'

import Ticker from '../src/site/components/Ticker'
import Modals from '../src/site/components/Modals'
import MobileMenu from '../src/site/components/MobileMenu'
import DemoButton from '../src/site/components/DemoButton'
import VideoButton from '../src/site/components/VideoButton'
import CommentForm from '../src/site/components/CommentForm'
import BFormSelect from 'bootstrap-vue'
import BFormInput from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import App from '../src/app/App'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import utmParams from '@site/lib/utmParams'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { setAmplitude } from '@lib/amplitude'

window.Events = new Vue()
Vue.use(VueRouter)
Vue.use(VModal)
Vue.use(VueCalendly)
Vue.use(BFormSelect)
Vue.use(BFormInput)
Vue.use(VueCookies)
Vue.use(PiniaVuePlugin)

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

// we use pinia on the website ONLY for the share modal - which
// taps into the LoadingStore, which is a pinia store
const pinia = createPinia()

Vue.filter('dayjs', (date, format) => {
  return dayjs(date).format(format)
})

document.addEventListener('DOMContentLoaded', () => {
  const routes = [
    { path: '/user-research-library', component: AccountIndex, props: true },
    { path: '/user-research-library/:id', component: AccountShow, props: true },
    {
      path: '/embedded-user-research-library/:id',
      component: AccountShow,
      props: { isEmbedded: true },
    },
    { path: '/assets/:id', component: AssetShow, props: true },
    { path: '/sites/:slug', component: AssetListShow, props: { isEmbedded: false } },
    { path: '/sites/embed/:slug', component: AssetListShow, props: { isEmbedded: true } },
    { path: '/sites/:slug/:token', component: AssetListShow, props: true },
  ]

  const router = new VueRouter({
    mode: 'history',
    routes: routes,
  })

  if (document.getElementById('assets_show')) {
    new Vue({
      el: '#assets_show',
      router,
      pinia,
      render: (h) => h(App),
    })
  }

  if (document.getElementById('accounts_index')) {
    new Vue({
      el: '#accounts_index',
      router,
      pinia,
      render: (h) => h(App),
    })
  }

  if (document.getElementById('accounts_show')) {
    new Vue({
      el: '#accounts_show',
      router,
      pinia,
      render: (h) => h(App),
    })
  }

  if (document.getElementById('asset_lists_show')) {
    new Vue({
      el: '#asset_lists_show',
      router,
      pinia,
      render: (h) => h(App),
    })
  }

  if (document.getElementById('comment_form')) {
    new Vue({
      el: '#comment_form',
      components: { CommentForm },
    })
  }

  if (document.getElementById('modals')) {
    new Vue({
      el: '#modals',
      components: { Modals },
    })
  }

  if (document.getElementById('mobile_menu')) {
    new Vue({
      el: '#mobile_menu',
      components: { MobileMenu },
    })
  }

  document.querySelectorAll('.demo_button').forEach((button) => {
    new Vue({
      el: button,
      components: { DemoButton },
    })
  })
  if (document.getElementById('video_button')) {
    new Vue({
      el: '#video_button',
      components: { VideoButton },
    })
  }

  if (document.getElementById('ticker')) {
    new Vue({
      el: '#ticker',
      components: { Ticker },
    })
  }

  utmParams.setup()
})
