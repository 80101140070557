import { Model } from '@vuex-orm/core'
import Survey from './Survey'
import Answer from './Answer'
import Response from './Response'
import ContentAsset from './ContentAsset'

export default class Question extends Model {
  static entity = 'questions'

  static fields() {
    return {
      id: this.number(null),
      position: this.number(null),
      type: this.string(''),
      name: this.string(''),
      category: this.string(''),
      distribution_direction: this.string(''),
      the_question: this.attr(null),
      survey_id: this.number(null),
      answer_stats: this.attr(null),
      recipient_count: this.attr(null),
      response_count: this.attr(null),
      content_asset_id: this.attr(null),
      effective_recipient_count: this.attr(null),
      effective_response_count: this.attr(null),
      survey: this.belongsTo(Survey, 'survey_id'),
      content_asset: this.belongsTo(ContentAsset, 'content_asset_id'),
      answers: this.hasMany(Answer, 'question_id'),
      responses: this.hasMany(Response, 'question_id'),
      display_order: this.attr(null),
      min_value: this.attr(null),
      max_value: this.attr(null),
      step_value: this.attr(null),

      // NOTE: VueORM hasMany relationship is not working for MatrixxQuestion
      // for some reason. So we are using a regular array attribute here
      // for storing the MatrixxRows
      rows: this.attr([]),
    }
  }
}
