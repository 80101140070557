<template lang="html">
  <div class="basic-header">
    <div class="d-flex align-items-center">
      <span v-if="logoSvg" v-html="logoSvg" class="logo" aria-hidden></span>
    </div>
    <div v-if="title" class="title font-weight-bold mt-3">{{ title }}</div>
    <div v-else-if="question" class="question font-weight-bold mt-3">{{ question }}</div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    logoSvg: { type: String, required: true }, // all assets show a logo
    question: { type: String, required: false }, // stats don't show the question
    title: { type: String, required: false }, // testimonials do have an optional title
  },
}
</script>
<style lang="sass" scoped>
.logo
  display: inline-block
  height: 24px
  ::v-deep svg
    width: 100%
    height: 100%
.question, .title
  font-size: 1rem
  font-weight: 600
</style>
